<template>
    <div>
        <el-dialog :title="title" v-model='show' width="400px" :before-close="close">
            <slot name="formRegion" :form='formdata'></slot>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="close">取 消</el-button>
                    <el-button @click="confirm">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
    import { ref, watch, reactive } from 'vue'
    export default {
        props: ['title', 'visible', 'form'],
        setup(props, { emit }) {
            let show = ref(false)
            let formdata = reactive(props.form)
            watch(() => [props.visible, props.form], ([newv, newf], [oldv, newo]) => {
                show.value = newv
                formdata = newf
            })

            function confirm() {
                emit('confirm', formdata)
            }
            function close() {
                emit('close')
            }
            return {
                show,
                formdata,
                confirm,
                close,
            }
        },
        mounted() {
        }
    }
</script>