<template>
    <el-button type="primary" circle size="mini" icon='el-icon-plus' @click="openEmptyDialog"></el-button>

    <FormDialog :title='title' :visible='visible' :form='selected' @confirm='addRole' @close='closeDialog'>
        <template #formRegion='scope'>
            <el-form label-position="left" size="mini" ref="formref" :rules='formRules' status-icon label-width="80px"
                :model="scope.form">
                <el-form-item label="角色编码" prop='roleCode'>
                    <el-input v-model="scope.form.roleCode" :disabled='scope.form.id > 0'></el-input>
                </el-form-item>
                <el-form-item label="角色名" prop='roleName'>
                    <el-input clearable v-model="scope.form.roleName"></el-input>
                </el-form-item>
                <el-form-item label="角色描述">
                    <el-input clearable v-model="scope.form.roleDesc"></el-input>
                </el-form-item>
            </el-form>
        </template>
    </FormDialog>

    <el-table :data="roleList" stripe border highlight-current-row style="width: 100%">
        <el-table-column prop="roleName" label="角色名" show-overflow-tooltip width="140">
            <template #default="scope">
                <el-link :underline="false" :href="'./role/'+scope.row.id" type="primary">{{scope.row.roleName}}
                </el-link>
            </template>
        </el-table-column>
        <el-table-column prop="roleCode" label="角色编码" show-overflow-tooltip width="120"></el-table-column>
        <el-table-column prop="roleDesc" label="角色描述"></el-table-column>
        <el-table-column prop="base.created" label="创建时间" show-overflow-tooltip width="170"></el-table-column>
        <el-table-column prop="base.creatorName" label="创建人" show-overflow-tooltip width="80"></el-table-column>
        <el-table-column prop="base.updated" label="更新时间" show-overflow-tooltip width="170"></el-table-column>
        <el-table-column prop="base.updaterName" label="更新人" show-overflow-tooltip width="80"></el-table-column>
        <el-table-column prop="base.updaterName" label="操作" width="80">
            <template #default="scope">
                <el-button icon='el-icon-edit' type="text" class="button" @click='process("edit", scope.row)'>
                </el-button>
                <el-popconfirm title='确认删除？' confirmButtonText='删除' cancelButtonText='取消'
                    @confirm='process("delete",scope.row)'>
                    <template #reference>
                        <el-button icon='el-icon-circle-close' type="text" class="button"> </el-button>
                    </template>
                </el-popconfirm>

            </template>
        </el-table-column>
    </el-table>
    <div class="block" style="text-align:right">
        <el-pagination small @size-change="pageSizeChange" @current-change="search" v-model:currentPage="currentPage"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="sizes, prev, pager, next" :total="total">
        </el-pagination>
    </div>
</template>
<script>
    import { roleSearch, roleAdd, roleRemove } from '@/utils/api'
    import { getPathVariableAppKey } from '@/utils/tools'
    import { ref, reactive, provide } from 'vue'
    import FormDialog from '@/components/FormDialog'

    export default {
        components: { FormDialog },
        setup() {
            const appkey = getPathVariableAppKey()
            let currentPage = ref(1)
            let pageSize = ref(10)
            let total = ref(0)

            let title = ref('新增角色')
            let visible = ref(false)
            let roleList = ref([])
            let selected = reactive(initSelected())
            //搜索角色
            function search() {
                let request = {
                    pageNo: currentPage.value,
                    pageSize: pageSize.value
                }
                roleSearch(appkey, request).then(res => {
                    roleList.value = res.data.list
                    total.value = res.data.total
                })
            }
            function openEmptyDialog() {
                visible.value = true
                selected = initSelected()
            }
            function closeDialog() {
                visible.value = false
            }
            function initSelected() {
                return {
                    id: 0,
                    roleCode: '',
                    roleName: '',
                    roleDesc: ''
                }
            }

            // 删除角色
            function process(key, row) {
                if (row) {
                    if (key == 'delete') {
                        let data = { ids: [row.id] }
                        roleRemove(appkey, data).then(res => {
                            search()
                        })
                    }
                    if (key == 'edit') {
                        visible.value = true
                        selected.id = row.id
                        selected.roleCode = row.roleCode
                        selected.roleName = row.roleName
                        selected.roleDesc = row.roleDesc
                    }
                }
            }
            function pageSizeChange(ps) {
                pageSize.value = ps
                search()
            }

            return {
                appkey,
                roleList,
                title,
                visible,
                selected,

                search,
                process,
                openEmptyDialog,
                closeDialog,

                currentPage,
                pageSize,
                total,
                pageSizeChange,

                formRules: {
                    roleCode: [
                        { required: true, message: '请输入角色编码', trigger: 'change' },
                    ],
                    roleName: [
                        { required: true, message: '请输入角色名称', trigger: 'change' },
                    ]
                },
            }

        },
        methods: {
            addRole(form) {
                let _this = this
                this.$refs['formref'].validate((valid) => {
                    console.log(valid)
                    if (valid) {
                        let request = {
                            id: form.id,
                            roleCode: form.roleCode,
                            roleName: form.roleName,
                            roleDesc: form.roleDesc,
                        }
                        _this.closeDialog()
                        roleAdd(_this.appkey, request).then(res => {
                            _this.search()
                        })
                    } else {
                        return false;
                    }
                });
            }
        },
        mounted() {
            this.search()
        }
    }
</script>